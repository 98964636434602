import React, { useState } from "react";
import { Link, useStaticQuery, graphql } from "gatsby";
import { GatsbyImage, getImage, StaticImage } from "gatsby-plugin-image";

import { ImgComparisonSlider } from "@img-comparison-slider/react";
import { Splide, SplideSlide } from "@splidejs/react-splide";

import "@splidejs/splide/dist/css/themes/splide-default.min.css";

import Layout from "../../components/layout";
import SEO from "../../components/seo";
import NewsletterBanner from "../../components/newsletterBanner";
import StereaImage from "../../components/stereaImage";
import { stringToSlug } from "../../utils/stringManipulation";

const query = graphql`
  query {
    allStrapiGallerysection {
      nodes {
        id
        category {
          Name
        }
        nome
        gallery_items {
          contenuto {
            prima {
              url
            }
            dopo {
              url
            }
            immagine {
              alternativeText
              formats {
                large {
                  width
                  url
                }
                medium {
                  url
                  width
                }
                small {
                  url
                  width
                }
              }
              width
              url
            }
          }
        }
      }
    }
  }
`;

const IndexPage = () => {
  const [sectionShown, setSectionShown] = useState(null);

  const data = useStaticQuery(query);

  var groupBy = function (xs, keyGetter) {
    return xs.reduce(function (rv, x) {
      (rv[keyGetter(x)] = rv[keyGetter(x)] || []).push(x);
      return rv;
    }, {});
  };

  return (
    <Layout>
      <SEO title="Galleria - Sterea Medicina e Viaggi" />

      <div className="sterea-center-column-wrapper overflow-hidden relative">
        <div className="color-overlay absolute w-full h-full bg-sterea-dark-blue bg-opacity-40 z-20"></div>

        <StaticImage
          src="../../images/vilnius-skyline.jpeg"
          style={{ position: "absolute" }}
          className="w-full h-full z-10"
          objectFit="cover"
          alt="Foto di un viaggiatore seduto in un aeroporto"
        />

        <div className="sterea-center-column h-3/4-screen z-20 flex justify-center items-center">
          <h1 className="w-11/12 text-white text-3xl lg:text-4xl leading-normal text-center font-bold">
            Galleria
          </h1>
        </div>
      </div>

      {/* <div className="sterea-center-column-wrapper">
        <div className="sterea-center-column flex flex-wrap justify-center py-12 px-min -mb-3">
          <div className="sterea-blue-button mr-3 mb-3">
            <Link to="/blog/chirurgia-estetica">
              Chirurgia estetica
            </Link>
          </div>
          <div className="sterea-button bg-sterea-light-gray filter drop-shadow-sm mr-3 mb-3">Trapianto capelli</div>
          <div className="sterea-button bg-sterea-light-gray filter drop-shadow-sm mr-3 mb-3">Fertilità</div>
          <div className="sterea-button bg-sterea-light-gray filter drop-shadow-sm mr-3 mb-3">Odontoiatria</div>
          <div className="sterea-button bg-sterea-light-gray filter drop-shadow-sm mr-3 mb-3">Turismo medico</div>
          <div className="sterea-button bg-sterea-light-gray filter drop-shadow-sm mb-3">Generale</div>
        </div>
      </div> */}

      {typeof window !== "undefined" && (
        <div className="sterea-center-column-wrapper">
          <div className="sterea-center-column px-min py-12">
            <div className="flex flex-col justify-center">
              <h2 className="text-center mb-12">Chirurgia Estetica</h2>
              <div className="border-b border-black"></div>
              {groupBy(
                data.allStrapiGallerysection.nodes,
                (section) => section.category.Name
              )["Chirurgia Estetica"].map((section) => (
                <>
                  <div
                    className="py-md px-sm cursor-pointer"
                    onClick={() =>
                      setSectionShown(
                        sectionShown == section.nome
                          ? null
                          : section.nome
                      )
                    }
                  >
                    <div className="flex justify-between text-lg">
                      <div className="font-medium">{section.nome}</div>
                      <div>
                        {sectionShown == section.nome ? "-" : "+"}
                      </div>
                    </div>
                  </div>
                  {sectionShown == section.nome && (
                    <div className="py-lg">
                      <div className="w-full">
                        <Splide
                          options={{
                            type: "loop",
                            pagination: false,
                            perPage: 1,
                            perMove: 1,
                            drag: false,
                            arrows: section.gallery_items.filter(item => item.contenuto[0] && item.contenuto[0].prima).length > 1,
                            // gap: "1rem",
                            // lazyLoad: "nearby",
                          }}
                          className="mb-12"
                        >
                          {section.gallery_items.map(
                            (galleryItem) =>
                              galleryItem.contenuto[0] &&
                              galleryItem.contenuto[0].prima &&
                              galleryItem.contenuto[0].dopo && (
                                <SplideSlide className="flex justify-center items-center">
                                  <ImgComparisonSlider>
                                    <img
                                      slot="before"
                                      src={galleryItem.contenuto[0].prima.url}
                                    />
                                    <img
                                      slot="after"
                                      src={galleryItem.contenuto[0].dopo.url}
                                    />
                                  </ImgComparisonSlider>
                                </SplideSlide>
                              )
                          )}
                        </Splide>
                        {section.gallery_items.filter(item => item.contenuto[0] && item.contenuto[0].immagine).length > 0 && (
                          <>
                            <Splide
                              options={{
                                type: "loop",
                                pagination: false,
                                perPage:
                                  section.gallery_items.length < 3
                                    ? section.gallery_items.length
                                    : 3,
                                perMove: 1,
                                arrows: section.gallery_items.filter(item => item.contenuto[0] && item.contenuto[0].immagine).length > 3,
                                gap: "1rem",
                                lazyLoad: "nearby",
                              }}
                              className="px-24 hidden lg:block"
                            >
                              {section.gallery_items.map(
                                (galleryItem) =>
                                  galleryItem.contenuto[0] &&
                                  galleryItem.contenuto[0].immagine && (
                                    <SplideSlide className="h-64 flex justify-center items-center">
                                      <StereaImage
                                        images={
                                          galleryItem.contenuto[0].immagine
                                        }
                                        sizes={"20vw"}
                                        alt={
                                          galleryItem.contenuto[0].immagine
                                            .alternativeText
                                        }
                                      />
                                    </SplideSlide>
                                  )
                              )}
                            </Splide>

                            <Splide
                              options={{
                                type: "loop",
                                pagination: false,
                                perPage: 1,
                                perMove: 1,
                                arrows: section.gallery_items.filter(item => item.contenuto[0] && item.contenuto[0].immagine).length > 1,
                                // gap: "1rem",
                                // lazyLoad: "nearby",
                              }}
                              className="lg:hidden"
                            >
                              {section.gallery_items.map(
                                (galleryItem) =>
                                  galleryItem.contenuto[0] &&
                                  galleryItem.contenuto[0].immagine && (
                                    <SplideSlide className="flex justify-center items-center">
                                      <StereaImage
                                        images={
                                          galleryItem.contenuto[0].immagine
                                        }
                                        sizes={"70vw"}
                                        alt={
                                          galleryItem.contenuto[0].immagine
                                            .alternativeText
                                        }
                                      />
                                    </SplideSlide>
                                  )
                              )}
                            </Splide>
                          </>
                        )}
                      </div>
                    </div>
                  )}
                  <div className="border-b border-black"></div>
                </>
              ))}
              {/* {data.allStrapiGallerysection.nodes.map((intervento) => (
              <Link
                to={`/interventi/${stringToSlug(intervento.nome)}`}
                className="sterea-procedure-card-wrapper"
              >
                <div className="sterea-procedure-card">
                  {intervento?.icona && intervento?.icona?.localFile &&
                    (intervento?.icona?.localFile?.extension === "svg" ? (
                      <img
                        src={intervento?.icona?.localFile?.publicURL}
                        className="mb-6 h-32"
                        alt={`Icona che rappresenta un intervento di ${intervento.nome}`}
                      />
                    ) : (
                      <GatsbyImage
                        image={getImage(intervento?.icona?.localFile)}
                        className="h-96"
                        objectFit="cover"
                        alt={`Icona che rappresenta un intervento di ${intervento.nome}`}
                      />
                    ))}
                  <h4 className="mt-6 font-bold text-center">
                    {intervento.nome}
                  </h4>
                </div>
              </Link>
            ))} */}
            </div>
          </div>
        </div>
      )}

      <NewsletterBanner />
    </Layout>
  );
};

export default IndexPage;
